export const TOGGLE_CLASS_NAME = 'TOGGLE_CLASS_NAME';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_LOADER = 'SHOW_LOADER';
export const SET_ROLES = 'SET_ROLES';
export const ENTITY_EDIT = 10010;
export const PERSON_INVITE = 10109;
export const ENTITY_DELETE = 10003;
export const PERSON_CREATE = 10102;
export const PERSON_EDIT = 10110;
export const PERSON_DELETE = 10103;
export const ENTITY_CREATE = 10002;
export const PERSON_ALL = 10111;
export const ENTITY_ALL = 10011;
export const LOAD_PERMISSIONS = 'LOAD.PERMISSIONS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const REMOVE_FILE = 'REMOVE_FILE';
export const IMG_DETAILS = 'IMG_DETAILS';
export const SYSTEM = 'sys';
export const API = 'api';
export const BRB = 'brb';
export const CARD_LOADER = 'CARD_LOADER';
export const RESET_CARD_LOADER = 'RESET_CARD_LOADER';
export const FETCH_CONFIG_FULFILLED = 'FETCH_CONFIG_FULFILLED';
export const FETCH_CONFIG_REJECTED = 'FETCH_CONFIG_REJECTED';
export const API_ROOT = 'API_ROOT';
export const MOAT_API_ROOT = 'MOAT_API_ROOT';
export const DCM_SERVER_URL = 'DCM_SERVER_URL';
export const SOCKET_ROOT = 'SOCKET_ROOT';
export const LOCAL = 'LOCAL';
export const BRB_URL = 'BRB_URL';
export const RMA_URL = 'RMA_URL';
export const API_CONFIG_LOADED = 'API_CONFIG_LOADED';
export const SET_SOCKET_TOKEN = 'SET_SOCKET_TOKEN';
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const SUPPORT_URL = 'SUPPORT_URL';
export const ADMIN = 'Admin';
export const SUPPORT = 'Support'
export const ERROR = 'ERROR';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const REQUEST_DATA = 'REQUEST_DATA';
export const UPDATE_BUTTON_FOR_MODAL = 'Update';
export const RESET_TOASTER = 'RESET_TOASTER';
export const RESET_MODAL_ERROR_MSG = 'RESET_MODAL_ERROR_MSG';
export const APP_COMPONENT = 'APP_COMPONENT';
export const RP_JS_HELP = 'RP_JS_HELP';
export const CP_JS_HELP = 'CP_JS_HELP';
export const SYSTEM_MAINTENANCE_WARNING = 'SYSTEM_MAINTENANCE_WARNING';
export const SOFTWARE_UPDATE_WARNING = 'SOFTWARE_UPDATE_WARNING';
export const MENU_LIST = 'MENU_LIST';
export const PARTNER_DELETE = 21703;
export const PARTNER_EDIT = 21710;
export const ALL_PARTNER_LIST = 21704 ;
export const PARTNER_INVITE = 21709;
export const PARTNERSHIP_LIST = 21701;

/* SUCCESS MESSAGES */
export const NO_CHANGE_IN_UPDATE = 'No change in the data to update';

export const RETAIL_MEDIA_SCOPE = 'rma';
export const RETAIL_MEDIA_DEMO_SCOPE = 'demo';
export const JIVOX_IQ_DAVINCI = 'Jivox IQ DaVinci';