export const SET_ALL_PEOPLES = 'SET_ALL_PEOPLES';
export const SHOW_PEOPLE_LOADER = 'SHOW_PEOPLE_LOADER';
export const SHOW_ADD_PERSON_LOADER = 'SHOW_ADD_PERSON_LOADER';
export const SHOW_SUBMIT_PERSON_LOADER = 'SHOW_SUBMIT_PERSON_LOADER';
export const SHOW_ACC_INVITE_LOADER = 'SHOW_ACC_INVITE_LOADER';
export const SHOW_EDIT_PERSON_LOADER = 'SHOW_EDIT_PERSON_LOADER';
export const UPDATE_PERSON_DETAILS = 'UPDATE_PERSON_DETAILS';
export const SHOW_GENERATE_KEY_LOADER = 'SHOW_GENERATE_KEY_LOADER';
export const SHOW_CREATE_KEY_LOADER = 'SHOW_CREATE_KEY_LOADER';
export const SET_API_KEY = 'SET_API_KEY';
export const SET_ASSOCIATED_ACCOUNT = 'SET_ASSOCIATED_ACCOUNT';
export const ADD_PERSON = 'ADD_PERSON';
export const CREATE_PERSON_COMPONENT = 'CREATE_PERSON_COMPONENT';
export const CREATE_ADVERTISER_COMPONENT = 'CREATE_ADVERTISER_COMPONENT';
export const CREATE_DEMO_USER_COMPONENT = 'CREATE_DEMO_USER_COMPONENT';
export const EDIT_PERSON_COMPONENT = 'EDIT_PERSON_COMPONENT';
export const GENERATE_KEY_COMPONENT = 'GENERATE_KEY_COMPONENT';
export const ADDTO_ACC_COMPONENT = 'ADDTO_ACC_COMPONENT';
export const ALL_PEOPLE_COMPONENT = 'ALL_PEOPLE_COMPONENT';
export const DELETE_PERSON_COMPONENT = 'DELETE_PERSON_COMPONENT';
export const REINVITE_PERSON_COMPONENT = 'REINVITE_PERSON_COMPONENT';
export const REACTIVATE_PERSON_COMPONENT = 'REACTIVATE_PERSON_COMPONENT';
export const REACTIVATE_PERSON_TITLE = 'Reactivate Person';
export const SET_INITIAL_EDIT_PERSON_DETAILS = 'SET_INITIAL_EDIT_PERSON_DETAILS';

/* SUCCESS MESSAGE */

export const ADD_PERSON_SUCCESS_MSG = 'Person created successfully';
export const ADD_ADVERTISER_SUCCESS_MSG = 'Advertiser created successfully';
export const ADD_DEMO_USER_SUCCESS_MSG = 'Demo User created successfully';
export const GENERATE_KEY_SUCCESS_MSG = 'API Key Generated Successfully';
export const ADDTO_ACC_SUCCESS_MSG = 'Invitation Sent';
export const DELETE_PERSON_SUCCESS_MSG = 'Person deleted successfully';
export const EDIT_PERSON_SUCCESS_MSG = 'Person updated Successfully';
export const REINVITE_PERSON_SUCCESS_MSG = 'Reinvited Person successfully';
export const REACTIVATE_PERSON_SUCCESS_MSG = 'Reactivated Person successfully';

export const userTooltipMessage = 'Create a new user account for adding users from your organization. Select this option if you want to add new users to your organization in Jivox IQ.';
export const advertiserTooltipMessage = 'Create a new user account for your DaVinci advertiser users. Select this option if you want to add external advertiser (vendor or brand) users and give them access to only the DaVinci self-serve platform.';
export const demoUserTooltipMessage = 'Add demo user';
export const selectRetailAccountsTooltipMessage = "User will be granted access to the selected DaVinci Advertiser accounts. You can revoke access to an account by clicking on the 'Explicit Permission' icon in the account card in the All Accounts page and removing all roles for the user.";
export const userRoleTooltipMessage = "All external DaVinci Advertiser users are assigned the 'Advertiser' role by default. This role grants them permissions to access the DaVinci self-serve platform and activate campaigns. You can modify the user role by clicking on the 'Explicit Permission' icon in the account card.";