import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../login/fontawesome-pro-6.2.0-web/css/all.min.css';
import 'antd/dist/antd.css';
import '../../css/app.css';
import Header from './Header';
import SideNav from './SideNav';
import Logo from './Logo';
import { Route } from 'react-router-dom';
import ErrorBoundary from '../../commonUI/errorBoundary/errBoundary';
import Loader from '../../commonUI/loader/withLoader';
import { getPermissionAction } from '../actions/permissionAction';
import { getRolesAction, setApiConfigAction, getSocketTokenAction, setupSocket, appLoader, selectError } from '../actions/appAction';
import { coBrandDetails, coBrandDetailsRMA } from '../../dashboard/actions/dashboardAction';
import TenantSessionExists from './TenantSessionExists';
import {NotificationContainer} from 'react-notifications';
import * as appConstants from '../../constants/appConstants.js';
import { MEMBER_ROLE, RETAILER_ORG, RMA_ROLES } from '../../constants/accountConstants';
import ApiConfigClass  from '../../config/apiConfig';
import CSSVariableApplicator from '../../commonUI/CSSVariableApplicator/CSSVariableApplicator.js'
import {isEmpty} from '../../helper/commonFunc.js'
import styles from '../../css/accCard.module.css';

const mapStateToProps = state => {
  return {
    heightClassName: state.toggleSideMenuReducer.heightClassName,
    sideNavClass: state.toggleSideMenuReducer.sideNavClass,
    loadingData: state.appReducer.loadingData,
    apiConfig: state.appReducer.apiConfig,
    socketToken: state.appReducer.socketToken,
    theme: state.dashboardReducer.theme,
    coBrandDetails: state.dashboardReducer.coBrandDetails,
    coBrandDetailsRMA: state.dashboardReducer.coBrandDetailsRMA,
    permissionDetails: state.permissionReducer.authorizationDetails,
    appCompError: selectError(state, appConstants.APP_COMPONENT)
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserPermissions: (storePath, callback) => {
            dispatch(getPermissionAction(storePath, callback));
        },
        getRoles: (actionData) => {
            dispatch(getRolesAction(actionData));
        },
        setApiConfig: (callback) => {
            dispatch(setApiConfigAction(callback));
        },
        getSocketToken: (callback) => {
            dispatch(getSocketTokenAction(callback));
        },
        setupSocketAction: (token) => {
            dispatch(setupSocket(token));
        },
        getCoBrandDetails: (storePath, callback) => {
            dispatch(coBrandDetails(storePath, callback));
        },
        getCoBrandDetailsRMA: (storePath, callback) => {
            dispatch(coBrandDetailsRMA(storePath, callback));
        },
        loader: () => {
            dispatch(appLoader());
        }
    };
}

class AppComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantURL: '',
            loaderColor: '#BD10E0',
            isWhiteLabellingEnabled: ""
        }
    }
    componentDidMount() {  
        this.props.setApiConfig(this.apiConfCallback.bind(this));
    }

    apiConfCallback(response) {
        var isConfigLoaded = ApiConfigClass.getData(appConstants.API_CONFIG_LOADED);

        if(isConfigLoaded) {
            let storePath = appConstants.APP_COMPONENT;
            let actionData = {
                storePath: storePath,
                actionType: appConstants.SET_ROLES,
                url: '/roles?fields=id,name&sortBy=name&orderBy=asc'
            };
            this.props.getUserPermissions(storePath, this.respcallback.bind(this));  
            this.props.getRoles(actionData); 
            /* WEB SOCKET INITIAL CALL */
            // this.props.getSocketToken(this.socketcallback.bind(this));
        }
    }

    hasValidScopes(scopes) {
        /* 
         using scopes array to check if user is a retail user
         - if scopes has only rma then redirect to rma
		 - if scopes has only demo then redirect to rma
         - if it has rma and sys(eam) redirect to EAM
        */
        return scopes && scopes.length === 1 && 
			(scopes[0] === appConstants.RETAIL_MEDIA_SCOPE || scopes[0] === appConstants.RETAIL_MEDIA_DEMO_SCOPE);
    }
    
    redirect(scopes){
            return this.hasValidScopes(scopes);
    }

    respcallback(response) {
        if(!this.props.appCompError) {
            var isLOCAL = ApiConfigClass.getData(appConstants.LOCAL);
            if(!!response.orgSettings && response.orgSettings.isWhiteLabellingEnabled) {
                let storePath = appConstants.APP_COMPONENT;
                this.props.getCoBrandDetails(storePath, this.getCoBrandCallback.bind(this));
            } else {
                this.props.loader();
                this.setState({isWhiteLabellingEnabled: "disabled"});
            }
    
            if(!isLOCAL && response.tenantURL && window.location.hostname.toLowerCase() !== response.tenantURL.toLowerCase() && response.tenantURL.toLowerCase() !== '*.jivox.com') {
               this.setState({
                   tenantURL: response.tenantURL
               })
            } else {
                this.setState({
                    tenantURL: ''
                })
            }
            if(response.organizationType && response.organizationType === 'Retailer'){
                //get retail media variables only if retailer account
                this.props.getCoBrandDetailsRMA(appConstants.APP_COMPONENT);
            }
        }else {
            this.props.loader();
        }
    }

    getCoBrandCallback(response) {
        this.setState({isWhiteLabellingEnabled : "enabled"});
        this.props.loader();
    }
    

    socketcallback(response) {
       // console.log('socketToken',this.props.socketToken)
       // this.props.setupSocketAction(this.props.socketToken);
    }

    respcallbackRoles(response) {
        //console.log(response);
    }

    render() {
        const { match, coBrandDetails } = this.props;
    
        let 
            shouldRedirect = match.url === window.location.pathname,
            navActiveClass = 'active', isAdmin = false,
            footerSelector = !isEmpty(coBrandDetails.selectorOptions) ? coBrandDetails.selectorOptions.filter(selector => selector.cssSelector === "footer") : {},
            showFooter = !isEmpty(footerSelector[0]) ? footerSelector[0].value : true,
            brandTitle = !isEmpty(coBrandDetails.varOptions) && coBrandDetails.varOptions.brandTitle ? coBrandDetails.varOptions.brandTitle : "",
            favicon = !isEmpty(coBrandDetails.varOptions) && coBrandDetails.varOptions.jvxLogoUrl ? coBrandDetails.varOptions.jvxLogoUrl : "",
            isFooterConfigPresent = !isEmpty(coBrandDetails.varOptions) ? Object.keys(coBrandDetails.varOptions).some(key => key === 'footerConfig') : false,
            footerConfig = isFooterConfigPresent ? JSON.parse(coBrandDetails.varOptions.footerConfig) : [];
        if(brandTitle !== ''){
            document.title = brandTitle;
        }
        else if(this.state.isWhiteLabellingEnabled !== ''){
            document.title = "Jivox - Enterprise Account Portal";
        }
        if(!!favicon){
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = favicon;
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        else if(this.state.isWhiteLabellingEnabled !== ''){
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/png';
            link.rel = 'shortcut icon';
            link.href = "/favicon.ico";
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        if (this.props.sideNavClass === 'nav-sm' || this.props.sideNavClass === "nav-sm "+styles.navStyleSm) {
            navActiveClass = 'active-sm';
        }
        return (
            this.props.appCompError ? <ErrorBoundary error={this.props.appCompError} /> :
            this.props.loadingData.fetching ?
                (
                    <div className={this.props.sideNavClass}>
                        <div className="container body">
                            <div id="init-modal">
                                <div className="loader-content">
                                    <br />
                                    <div>                                        
                                         <Loader loader={true} />
                                    </div> 
                                    <span className="loader-span">{this.props.loadingData.fetchingDetails}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) 
            :
            this.state.tenantURL ? 
                <TenantSessionExists tenantURL={this.state.tenantURL}/>
            :
            ( this.redirect(this.props.permissionDetails.userAppScopes) ) ?  
                <Route path='' component = 
                {
                    () => {
                        window.location.href = ApiConfigClass.getData(appConstants.RMA_URL);
                        return null;
                    }
                }
                /> 
            :
            <ErrorBoundary>
                <div className={this.props.sideNavClass}>
                    <div className="container body">
                        <NotificationContainer />
                        <CSSVariableApplicator variables={this.props.theme} />
                        <div className="main_container">
                            <Route render={() => <Logo />} />
                            <Route
                            render={() => <Header sideNavClass={this.props.sideNavClass} isAdmin={isAdmin} />}
                            />
                            <Route
                            render={() => (
                                <SideNav
                                    navActiveClass={navActiveClass}
                                    currentLocation={this.props.location.pathname}
                                    isAdmin={isAdmin}
                                    showFooter={showFooter}
                                    footerConfig={footerConfig}
                                />
                            )}
                            />
                            {shouldRedirect}
                        </div>
                    </div>
                </div>    
            </ErrorBoundary>       
        )
    }
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppComp);
export default App;
