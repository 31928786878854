import React from 'react';
import Input from '../input/input.js';
import ToggleBar from '../togglebar/toggleBar.js';
import TimePicker from '../../commonUI/TimePicker/timePicker';
import TextArea from '../../commonUI/textarea/textarea.js';
import Button from '../../commonUI/button/button.js';
import TooltipSelect from './reactTooltipSelect';
import Select from 'react-select';
import {AsyncSelectComp} from './AsycSelect';
import CheckedSelect from './reactCheckedSelect';
import Creatable from 'react-select/creatable';
import '../../css/reactSelect.css';
import {FormLabel} from './FormLabel';
import {FormError} from './FormError';
import { FormSuccess } from './FormSuccess.jsx';
import { FormWarning } from './FormWarning.jsx';

export const FieldGenerator = (props) => {
    var 
        JSX = '', 
        parent = props,
        formElement = parent.formElement.config,
        dataSource = props.selectConfig.dataSource,
        index = parent.formElement.id, 
        multiSelect = (formElement.elementConfig && formElement.elementConfig.multiSelect) ? formElement.elementConfig.multiSelect : false,
        isDisabled =  (formElement.elementConfig && formElement.elementConfig.isDisabled) ? formElement.elementConfig.isDisabled : false;
    switch(formElement.elementType) {     
        case 'creatable':
            JSX = 
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel formElement = {formElement} />
                    <Creatable
                        name={index}
                        getNewOptionData={(inputValue, optionLabel) => ({
                            label: optionLabel,
                            [parent.joinValuesBy]: inputValue,
                            __isNew__: true
                        })}
                        getOptionValue={parent.getOptionValue}
                        getOptionLabel={parent.getOptionLabel}
                        placeholder={formElement.elementConfig.label}
                        isClearable={true}
                        closeOnSelect={true}
                        isMulti={multiSelect}
                        options={dataSource}
                        onChange={(e) => parent.handleSelectCreateChange(e, index)}
                        onInputChange={(e) => parent.handleSelectInputChange(e, index)}
                        value={formElement.value}
                        promptTextCreator={(label) => `${parent.promptTextCreator} ${label}`}
                        ignoreCase={true}
                    />
                    <FormError parent = {parent}/>
                </div>
        break;
        case 'select':
            JSX = 
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel id={index} formElement = {formElement} />
                    <Select
                        aria-label={`label-${index}`}
                        // inputId={`label-${index}` }
                        name={index}
                        getOptionValue={parent.getOptionValue}
                        getOptionLabel={parent.getOptionLabel}
                        placeholder={formElement.elementConfig.label}
                        isClearable={true}
                        closeMenuOnSelect={multiSelect ? false : true}
                        isMulti={multiSelect}
                        options={dataSource}
                        isDisabled={isDisabled}
                        onChange={parent.handleSelectChange}
                        onInputChange={(e) => parent.handleSelectInputChange(e, index)}
                        value={formElement.value}
                    />
                    {!!formElement.toolTipContent ? <span className="info far fa-question-circle" onMouseEnter={parent.showToolTip} onMouseLeave={parent.hideToolTip} tooltipcontent={formElement.toolTipContent} data-tip data-for={formElement.id} ></span> : null}
                    <FormError parent = {parent}/>
                </div>
        break;
        case 'async':
            JSX =
            <div key={index} className={formElement.elementConfig.divclass}>
                <FormLabel id={index} formElement = {formElement} />
                <AsyncSelectComp
                    index={index}
                    parent={parent}
                    formelement = {formElement}
                    multiselect={multiSelect}
                    datasource={dataSource}
                    isdisabled={isDisabled}
                    searchmenudetails={props.selectConfig.searchMenuDetails}
                    fetchMenu={parent.fetchMenu}
                />
                <FormError parent = {parent}/>
            </div>
            break;
        case 'tooltipSelect':
                JSX =
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel formElement = {formElement} />
                    <TooltipSelect 
                        index={index}
                        parent = {parent}
						formelement = {formElement}
						isDisabled={formElement.elementConfig.isDisabled !== undefined 
										? formElement.elementConfig.isDisabled : false}
                        options={dataSource}
                        isClearable={true}
                        value={formElement.value}
                        isMulti={multiSelect}
                        onChange={parent.handleSelectChange}
                        onInputChange={(value) => parent.handleSelectInputChange(value, index)}
                    />
                    <FormError parent = {parent}/>
                </div>
            break;
        case 'checkedSelect':
            JSX =
            <div key={index} className={formElement.elementConfig.divclass}>
                <FormLabel formElement = {formElement} />
                <CheckedSelect 
                    index={index}
                    options={dataSource}
                    isClearable={true}
                    value={formElement.value}
                />
                <FormError parent = {parent}/>
            </div>
            break;
        case 'checkbox':
            JSX =
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel formElement = {formElement} />
                    <div className={formElement.elementConfig.infoClass}>
                    <ToggleBar switched={formElement.value} name ={formElement.elementConfig.name} toggleSwitch={(event) => parent.inputChangedHandler(event, index)} disabled={!!formElement.disabled} />
                    {!!formElement.infoText? <span className= {formElement.infoTextClass}>{formElement.infoText}</span>: null}
                    </div>
                    {!!formElement.toolTipContent ? <span className="ques far fa-question-circle" onMouseEnter={parent.showToolTip} onMouseLeave={parent.hideToolTip} tooltipcontent={formElement.toolTipContent} data-tip data-for={formElement.id} ></span> :null}
                    <FormError parent = {parent}/>
                </div>
            break;
        case 'title':
            JSX = 
                <div className={formElement.elementConfig.divclass} key={index}>
                    <h4 className={formElement.elementConfig.h4Class}>{formElement.elementConfig.label}</h4> 
                </div>
            break;
        case 'fieldGroupTitle':
            JSX = 
            <div className={formElement.elementConfig.divclass} key={index}>
                <h6>{formElement.elementConfig.label}</h6> 
                {!!formElement.toolTipContent ? <span className="ques far fa-question-circle" onMouseEnter={parent.showToolTip} onMouseLeave={parent.hideToolTip} tooltipcontent={formElement.toolTipContent} data-tip data-for={formElement.id} ></span> :null}
            </div>
            break;
        case 'divider':
            JSX =
                <div className={formElement.elementConfig.divclass} key={index}>
                    <hr />
                    <h4>{formElement.elementConfig.label}</h4> 
                </div>
            break;
        case 'timePicker':
            JSX = 
            <div key={index} className={formElement.elementConfig.divclass}>
                <FormLabel formElement = {formElement} />
                <TimePicker format = {formElement.format} id={index} elementType={formElement.elementType}
                    elementConfig={formElement.elementConfig}
                    value={formElement.value}
                    changed={(event) => parent.inputChangedHandler(event, index)} 
                />
                {!!formElement.toolTipContent ? <span className="time far fa-question-circle" onMouseEnter={parent.showToolTip} onMouseLeave={parent.hideToolTip} tooltipcontent={formElement.toolTipContent} data-tip data-for={formElement.id} ></span> :false}
                <FormError parent = {parent}/>
            </div>
            break;
        case 'button':
            JSX =
                <Button 
                    id={index} 
                    elementType={formElement.elementType}
                    btnObj={formElement.elementConfig} 
                    toolTipContent={formElement.toolTipContent} 
                    showToolTip={parent.showToolTip} 
                    hideToolTip={parent.hideToolTip} 
                    onClick={(event) => parent.handleFormBtnClick(event, index)}
                />
            break;
        case 'textArea':
            JSX = 
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel formElement = {formElement} />
                    <TextArea 
                        id={index}
                        formElement = {formElement}
                        changed={(event) => parent.inputChangedHandler(event, index)} 
                    />
                    <FormError parent = {parent}/>
                </div>
            break;
        case 'paragraph':
            JSX = 
                <div key={index} className={formElement.elementConfig.divclass}>
                    <FormLabel formElement = {formElement} />
                    <p className="margin-left-10 paracontent" data-testid={`paragraph-${index}`}>{formElement.value}</p>
                    <FormError parent = {parent}/>
                </div>
            break;
        case 'icon':
            JSX = <div onClick = {(e) => parent.handleIconClick(e, index)}>
                    <a className={formElement.elementConfig.iconClass}/>
                 </div>
            break;
      case 'textBlock':
        JSX = (
            <div key={index} className={formElement.elementConfig.divclass}>
                <span title={formElement.elementConfig.labelname} className={formElement.elementConfig.spanclass}> {formElement.elementConfig.labelname}</span>
            </div>
        );
        break;
        case 'customJSX':
            JSX = React.cloneElement(formElement.customJSX, {...formElement.customProps});
            break;
		case 'toggle':
			JSX = (
				<div className={formElement.elementConfig.divclass}> 
					<div>
						{formElement.elementConfig.label}
					</div>
                    <ToggleBar 
						name={formElement.elementConfig.name} 
						switched={formElement.elementConfig.switched} 
						toggleSwitch={formElement.elementConfig.toggleSwitch}
					/>
				</div>
			);
			break;
        case 'placeHolder':
            JSX = <div className={formElement.elementConfig.divclass}></div>
            break;
        default:
            JSX = 
                <div key={index} className={formElement.elementConfig.divclass}>
                    <Input 
                        id={index}
                        formElement= {formElement}
                        parent = {parent}
                        showToolTip={parent.showToolTip}
                        removeLogoImage = {parent.removeLogoImage}
                        removeUploadedFile = {(e) => props.removeUploadedFile(e, index)}
                        fileUpload={(event) => parent.fileUpload(event, index)} //index to identify the input identifier
                        spanclass={formElement.elementConfig.spanclass}
                        changed={(event) => parent.inputChangedHandler(event, index)}  
                        radioChange={(event) => parent.handleRadioChange(event, formElement.elementConfig.name)} 
                        checkboxChange={(event, checkboxId) => 
                            parent.handleCheckboxChange(event, formElement.elementConfig.name, checkboxId)}
                        onClick={(event) => parent.onClick(event, formElement.value, index)}
                        onBlur={parent.handleBlur}
                        onKeyDown={parent.handleKeyDown}
                        fileUploadId={index} //added this to upload more than 1 file in a form
						data-testid={parent && parent.id}
                    />
                    {!!formElement.toolTipContent ? <span className="info far fa-question-circle" onMouseEnter={parent.showToolTip} onMouseLeave={parent.hideToolTip} tooltipcontent={formElement.toolTipContent} data-tip data-for={formElement.id} ></span> :false}
                    <FormSuccess successMessage= {!!formElement && formElement.successMessage} />
                    <FormError parent = {parent}/>
                    <FormWarning warningMessage = { !!formElement && formElement.warningMessage } />
                </div>;
    }
    return JSX;
}