import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {toastr} from 'react-redux-toastr';

import ApiConfigClass  from '../../config/apiConfig';
import ReactTooltip from '../../commonUI/tooltip/tooltip.js';
import { CreateForm } from '../../commonUI/Form/createForm';
import { selectInputChange, inputChangedHandler, updateFormElement} from '../../helper/formHelper/updateForm';
import { formSubmit } from '../../helper/formHelper/submitForm';
import {scrollToView} from '../../helper/commonFunc';
import CustomizedExpansionPanels from '../../commonUI/Panel/Panel';
import ReduxToaster from '../../commonUI/notification/reduxToaster';
import { formActionsEnum, Partners, partnersInfo, payloadType } from '../../config/brandsafetyconfig';
import {useSubscription} from '../../helper/subscription/useSubscription';

import {DCM_SERVER_URL} from '../../constants/appConstants.js';
import { CARD_TYPES } from '../../constants/accountConstants.js';
import { SAVE_DCM_CONFIGURATION, SAVE_DCM_CONFIGURATION_SUCCESS,  GET_ADVERTISER_DETAILS, GET_CAMPAIGN_DETAILS, RESET_CURRENT_CONFIG, UPDATE_DCM_CONFIGURATION_SUCCESS, GET_ASSET_SOURCE_DATA, GET_ASSET_SOURCE_COLUMN_DATA} from '../../constants/trackersConstants';

import { selectSuccess, selectFetchingg, selectError, searchMenuAction } from '../../app/actions/appAction';
import { saveTrackerConfigAction, editTrackerConfigAction, resetCurrentConfigAction } from '../actions/partnerIntegrationAction';

import '../../commonUI/button/button.css';
import styles from '../../css/partnerIntegration.module.css';
import { setValidity } from '../../helper/formHelper/formValidation.js';
import { omit } from 'lodash';
import {getFilteredAssetSourceList} from '../service/partnerIntegrationService';

/**
 * Tracker Generation Model Type for a media plan.
 * Model-1 => creates 1 Ad + 1 creative for every tracker
 * Model-2 => creates 1 Ad and assigns all creatives to that Ad
*/
export const modelTypeEnum = {
	MODEL1: 'MODEL-1',
	MODEL2: 'MODEL-2'
}

export const modelTypeOptions = [
	{
        label: 'Model 1',
        value: modelTypeEnum.MODEL1,
        toolTipContent: `<b>Model 1</b> - We will create a 1-1 relationship between Ads and 
        Creatives in GCM where under a placement we will create
        multiple Ads and Associate individual creatives to each Ad.
        Suggested to be used when GCM Ad needs to be created
        per placement and creative name combination.`
	},
	{
        label: 'Model 2',
        value: modelTypeEnum.MODEL2,
        toolTipContent: `Model 2 - We will create a 1-Many relationship between Ads 
        and Creatives in GCM where under a placement we will create
        1 Ad and associate all the creatives to the Ad. Suggested
        to be used when GCM Ad needs to be created per placement
        and creative group combination`
	}
];

/**
 * sets value for tracker generation model. By default, it is Model-1.
*/
export const getTrackerGenerationModelValue = (value) => {
	if (value === modelTypeEnum.MODEL1 || value === modelTypeEnum.MODEL2) {
		return value;
	}
	return modelTypeEnum.MODEL1; //Model 1 is selected by default
}

export const GCMConfig = (props) => {
    const scrollConfig = {
        behavior: "smooth",
        block: "nearest"
    }
    const dcmTrackerConfig = props.config;
    const formAction = props.formAction;
    const isCreateForm = formAction === formActionsEnum.CREATE;
    const isEditForm = formAction === formActionsEnum.EDIT;
    const isViewForm = formAction === formActionsEnum.VIEW;
	const {accId, accType, accAdvertiserId, prevTrackerId, listRef, forwardedRef, handleButtonClick} = props;
	const isTemplateAccount = (accType === CARD_TYPES.account[1]);

    const {
        isLoading, success, trackerSaveErr, dcmAdvertiserList,
        dcmCampaignList, assetSourceList,assetSourceColumnsList, trackerConfiglist
    } = useSelector(state => {
		return {
            trackerConfiglist: state.allTrackerState.trackerConfig.trackerConfiglist,
            isLoading: selectFetchingg(state, SAVE_DCM_CONFIGURATION),
            success: selectSuccess(state, SAVE_DCM_CONFIGURATION),
            trackerSaveErr: selectError(state, SAVE_DCM_CONFIGURATION),
            dcmAdvertiserList: state.allTrackerState.dcmAdvertiserList.advertiserList,
            dcmCampaignList: state.allTrackerState.dcmCampaignList.campaignList,
            assetSourceList: state.allTrackerState.assetSourceList.assetSourceList,
            assetSourceColumnsList: state.allTrackerState.assetSourceColumnsList.columnList,
            isLoadingList: selectFetchingg(state, "GCM_TRACKER_CONFIG_LIST")
		}
	}, shallowEqual);

	const TemplateAccGcmIntegrationForm = {
		configName: {
            elementType: 'input',
            elementConfig: {
                name: 'configName',
                type: 'text',
                label: 'Configuration Name',
                divclass: 'user-input-wrp  form-group form-group-div col-md-6 col-sm-10 col-xs-10',
                inputClasses: 'inputText form-control'
            },
            value: !!dcmTrackerConfig.configName ? dcmTrackerConfig.configName : '',
            validation: {
                required: true,
                format: 'configurationName',
                characterLimit: 53
            },
            valid: false,
            touched: false,
            disabled: isViewForm || (isEditForm && !isTemplateAccount)
        },
        profileId: {
            elementType: 'input',
            elementConfig: {
                name: 'profileId',
                type: 'text',
                label: 'Profile Id',
                divclass: 'user-input-wrp form-group form-group-div col-md-5 col-sm-10 col-xs-10',
                inputClasses: 'inputText form-control'
            },
            value: !!dcmTrackerConfig.profileId ? dcmTrackerConfig.profileId : '',
            validation: {
                required: true,
                format: 'number',
                preventWhiteSpace: true,
                preventFloatingNumbers: true
            },
            valid: false,
            touched: false,
            disabled: isViewForm || (isEditForm && !isTemplateAccount)
        },
        verify: {
            elementType: 'button',
            elementConfig: {
                id: 'verify',
                type: 'button',
                buttonTitle: 'Verify',
                divclass: "user-input-wrp form-group form-group-div col-md-1 col-sm-10 col-xs-10",
                faIcon: "far fa-user-check margin-right-5",
                btnSize:'newPixel col-xs-3',
                btnGroup: false,
                showIcon: true,
                disabled: isViewForm
            },
            validation: {
                required: false,
            },
            valid: true,
            touched: false,
        }
	}
    const getAssetSource = (dataServiceId) => {
        const value = assetSourceList.find((assetSource) => assetSource.id === dataServiceId);
        return value;
    }

    const getAssetSourceColumn = (column) => {
        // const value = assetSourceColumnsList.find((column) => column.name === column);
        // return value; 
        return {id:1, name: column};
    }

    var GcmIntegrationForm = {
        configName: {
            elementType: 'input',
            elementConfig: {
                name: 'configName',
                type: 'simpleInput',
                placeholder: 'Configuration Name',
                divclass: 'user-input-wrp  form-group form-group-div col-md-12 col-sm-12 col-xs-12',
                inputClasses: 'inputText form-control',
                isDisabled: true
            },
            value: !!dcmTrackerConfig.configName ? dcmTrackerConfig.configName : '',
            validation: {
                required: true,
                format: 'configurationName',
                characterLimit: 53
            },
            valid: false,
            touched: false,
            disabled: isViewForm || isEditForm
        },
        profileId: {
            elementType: 'input',
            elementConfig: {
                name: 'profileId',
                type: 'text',
                label: 'Profile Id',
                divclass: 'user-input-wrp form-group form-group-div col-md-4 col-sm-10 col-xs-10',
                inputClasses: 'inputText form-control'
            },
            value: !!dcmTrackerConfig.profileId ? dcmTrackerConfig.profileId : '',
            validation: {
                required: true,
                format: 'number',
                preventWhiteSpace: true,
                preventFloatingNumbers: true
            },
            valid: false,
            touched: false,
            disabled: isViewForm || isEditForm
        },
        verify: {
            elementType: 'button',
            elementConfig: {
                id: 'verify',
                type: 'button',
                buttonTitle: 'Verify',
                divclass: "user-input-wrp form-group form-group-div col-md-2 col-sm-10 col-xs-10",
                faIcon: "far fa-user-check margin-right-5",
                btnSize:'newPixel col-xs-3',
                btnGroup: false,
                showIcon: true,
                disabled: isViewForm
            },
            validation: {
                required: false,
            },
            valid: true,
            touched: false,
        },
        placeholder1:{
            elementType: 'placeHolder',
            elementConfig: {
                divclass: "user-input-wrp form-group form-group-div col-md-3 col-sm-9 col-xs-10"
            }
        },
        dcmAdvertiserId: {
            elementType: 'async',
            elementConfig: {
                label : 'Search/Select Advertiser Name',
                name: "dcmAdvertiserList",
                divclass: 'user-input-wrp form-group form-group-div col-md-4 col-sm-10 col-xs-10',
                labelClass: '',
                multiSelect: false,
                defaultOptions: false,
                isDisabled: !isEditForm
            },
            value: !!dcmTrackerConfig.dcmAdvertiser ? dcmTrackerConfig.dcmAdvertiser : '',
            validation: {
                required: true,
                format: "option"
            },
            valid: false,
            touched: false,
        },
        dcmCampaignId: {
            elementType: 'async',
            elementConfig: {
                label: 'Search/Select GCM Campaign',
                name: "dcmCampaignName",
                divclass: 'user-input-wrp form-group form-group-div col-md-4 col-sm-10 col-xs-10',
                labelClass: '',
                multiSelect: false,
                defaultOptions: false,
                isDisabled: !isEditForm
            },
            value: !!dcmTrackerConfig.dcmCampaign ? dcmTrackerConfig.dcmCampaign : '',
            validation: {
                required: true,
                format: 'option'
            },
            valid: false,
            touched: false
        },
        trackerGenerationModel:{
            elementType: 'custom-radio',
            elementConfig: {
                label: 'Tracker Generation Model',
                type: 'radio',
                name: 'trackerGenerationModel',
                controlled: true,
                options: modelTypeOptions,
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-4 col-sm-10 col-xs-10',
            },
            value: getTrackerGenerationModelValue(dcmTrackerConfig.trackerGenerationModel ? dcmTrackerConfig.trackerGenerationModel:''),
            disabled: !isEditForm,
            validation: {
                required: true,
                format: 'option'
            },
            valid: false,
            touched: false,
        },
        customAdNameTitle:{
            elementType: "fieldGroupTitle",
            elementConfig: {
                label:"(Optional) Configure GCM Ad Names",
                divclass: `user-input-wrp form-group form-group-div col-md-12 col-sm-12 col-xs-12 ${styles.titleWrapper}`,
            },
            toolTipContent : `To Configure Ad Names in the GCM, map an AssetSource Column that contains Ad Name for the respective creatives <br>
                            <br>
                            Note: For Model-2, Creatives with same Ad name will be grouped together under the same GCM Ad. Currently, GCM limits upto 80 creatives within an Ad. Do not keep more than 80 creatives per Ad name in the Asset Source `
        },
        assetSource: {
            elementType: 'async',
            elementConfig: {
                label: 'Select AssetSource',
                name: "assetSource",
                divclass: 'user-input-wrp form-group form-group-div col-md-4 col-sm-10 col-xs-10',
                labelClass: '',
                multiSelect: false,
                defaultOptions: false,
                isDisabled: isViewForm
            },
            value: '',
            validation: {
                required: false,
            },
            valid: false,
            touched: false,
        },
        assetSourceColumn: {
            elementType: 'async',
            elementConfig: {
                label: 'Map Asset Source Column',
                name: "assetSourceColumn",
                divclass: 'user-input-wrp form-group form-group-div col-md-4 col-sm-10 col-xs-10',
                labelClass: '',
                multiSelect: false,
                defaultOptions: false,
                isDisabled: isViewForm
            },
            value: dcmTrackerConfig && !!dcmTrackerConfig.customAdNameMapping && !!dcmTrackerConfig.customAdNameMapping.length && !!dcmTrackerConfig.customAdNameMapping[0].column ? getAssetSourceColumn(dcmTrackerConfig.customAdNameMapping[0].column) : '',
            validation: {
                required: false,
                format: 'option'
            },
            valid: false,
            touched: false
        },
    };
    let configNameProps = !!dcmTrackerConfig.configName ? dcmTrackerConfig.configName : '';
    let oldCampaignId = !!dcmTrackerConfig.dcmCampaign ? dcmTrackerConfig.dcmCampaign.id : '';
    
    const [configDetails, onSaveConfig] = useState({});
    const [editConfigDetails, onEditConfig] = useState({});
    const [chunkSize, setChunkSize] = useState([1,3,3,3,3,3,3,3,3]);
    const [updatedGcmIntegrationForm, setStateForm] = useState(GcmIntegrationForm);
    const [showTooltip, toggleTooltip] = useState(false);
    const [toolTipContent, setTooltipContent] = useState("");
    const [tooltipId, setTooltipId] = useState("-1"); 
    const [isAdvertiserUpdateRequired, updateAdvertiser] = useState(false); 
    const [isCampaignUpdateRequired, updateCampaign] = useState(false);
    const [isAssetSourceUpdateRequired, updateAssetSource] = useState(false); 
    const [isAssetSourceColumnUpdateRequired, updateAssetSourceColumn] = useState(false);
	const [isExpanded, setExpanded] = useState(true);
	const [profileIdVerified, setProfileIdVerified] = useState(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const postApi = `/jivox/dcm/mediaplan?profileId=${configDetails.profileId}&dcmAdvertiserId=${configDetails.dcmAdvertiserId}&dcmCampaignId=${configDetails.dcmCampaignId}&dcmCampaignName=${encodeURIComponent(configDetails.dcmCampaignName)}&configName=${encodeURIComponent(configDetails.configName)}&trackerGenerationModel=${configDetails.trackerGenerationModel}&accountId=${accId}${
        configDetails.dataServiceId ? `&dataServiceId=${configDetails.dataServiceId}&assetSourceColumn=${encodeURIComponent(configDetails.assetSourceColumnName)}` : ""
    }`;
    const getApi = `/v2/accounts/${accId}/dcm-configurations`;
    const putApi = `/jivox/dcm/mediaplan?profileId=${editConfigDetails.profileId}&dcmAdvertiserId=${editConfigDetails.dcmAdvertiserId}&dcmCampaignId=${editConfigDetails.dcmCampaignId}&dcmCampaignName=${encodeURIComponent(editConfigDetails.dcmCampaignName)}&configName=${encodeURIComponent(editConfigDetails.configName)}&trackerGenerationModel=${editConfigDetails.trackerGenerationModel}&accountId=${accId}&oldDcmCampaignId=${oldCampaignId}${
        editConfigDetails.dataServiceId ? `&dataServiceId=${editConfigDetails.dataServiceId}&assetSourceColumn=${encodeURIComponent(editConfigDetails.assetSourceColumnName)}` : ""
    }`;
	const getTemplateApi = `/jivox/dcm/rma/config?templateAdvertiserIdx=${accAdvertiserId}`;
	const postTemplateApi = `/jivox/dcm/rma/config?templateAdvertiserIdx=${accAdvertiserId}&configName=${encodeURIComponent(configDetails.configName)}&profileId=${configDetails.profileId}`;
	const putTemplateApi = `/jivox/dcm/rma/config?templateAdvertiserIdx=${accAdvertiserId}&configId=${dcmTrackerConfig.configId}&configName=${encodeURIComponent(editConfigDetails.configName)}&profileId=${editConfigDetails.profileId}`;
    const postActionData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: '',
        actionCreator: saveTrackerConfigAction,
        endPoint: isTemplateAccount ? postTemplateApi : postApi,
        postData: configDetails,
        requestInfo: {
            accId: accId,
            endPoint: isTemplateAccount ? getTemplateApi : getApi,
            isDCM: true,
        }     
    }
    useSubscription(postActionData);

    const editActionData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: '',
        actionCreator: editTrackerConfigAction,
        endPoint: isTemplateAccount ? putTemplateApi : putApi,
        postData: editConfigDetails,
        requestInfo: {
            accId: accId,
            endPoint: isTemplateAccount ? putTemplateApi : getApi
        }     
    }
    useSubscription(editActionData);

    const resetForm = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: RESET_CURRENT_CONFIG,
        actionCreator: resetCurrentConfigAction,
        isUpdateRequired: success && isEditForm
    }
    useSubscription(resetForm);

	useEffect(() => {
		if(isTemplateAccount){
			setChunkSize([3]);
			setStateForm(TemplateAccGcmIntegrationForm);
		}
	}, []);

    useEffect(() => {
        if(success) {
			if(isTemplateAccount){
				setChunkSize([3]);
				setStateForm(TemplateAccGcmIntegrationForm);
			}
			else{
				setStateForm(GcmIntegrationForm);
			}
            scrollToView(listRef, scrollConfig);
        }
    }, [success])

    useEffect(() => {
        if(isEditForm) {
            props.checkIfFormEdited(false);
        }
    } , [ formAction ])

    let profileIdVal = updatedGcmIntegrationForm.profileId.value;
    
    let advertiserId = isTemplateAccount ? null: updatedGcmIntegrationForm.dcmAdvertiserId.value;
    advertiserId = advertiserId ? advertiserId.id : null;

    let assetSourceName = isTemplateAccount ? null: updatedGcmIntegrationForm.assetSource.value;
    let assetSourceColumn = isTemplateAccount ? null: updatedGcmIntegrationForm.assetSourceColumn.value;
    let assetSourceId = !!assetSourceName ? assetSourceName.id : null;

    useEffect(() => {
        if(!isTemplateAccount && !!accId && !isCreateForm && !!dcmTrackerConfig.customAdNameMapping.length){
            let copyForm = {...updatedGcmIntegrationForm};
            const value = getFilteredAssetSourceList(accId,dcmTrackerConfig.customAdNameMapping[0].dataServiceId).then((response) => {
                copyForm.assetSource.value = {id: response.assetSourceList[0].dataServiceId,name:response.assetSourceList[0].dataServiceName}
                setStateForm(copyForm);
            });
        }
    },[dcmTrackerConfig.customAdNameMapping])

    useEffect(() => {
        if(!isTemplateAccount){
            let copyForm = {...updatedGcmIntegrationForm};
            if(isCreateForm){
                if(!!advertiserId){ 
                    enableDependentFields(copyForm);
                    resetCampaign(copyForm);
                    updateCampaign(true);
                }else{
                    resetCampaign(copyForm);
                    copyForm["dcmCampaignId"].elementConfig.isDisabled = true;
                    updateCampaign(false);
                }
            }
            if(isEditForm){
            if(!advertiserId){
                copyForm["dcmCampaignId"].elementConfig.isDisabled = true;
            }else{
                copyForm["dcmCampaignId"].elementConfig.isDisabled = false;
                updateCampaign(true);
            } 
            }
            setStateForm(copyForm);
        }
    },[advertiserId])

    useEffect(() => {
        if(!isTemplateAccount){
            let copyForm = {...updatedGcmIntegrationForm}
            if(!!assetSourceName){
                if(!!assetSourceColumn && !!assetSourceColumn.length){
                    copyForm['assetSourceColumn']['validation']['required'] = false;
                    setValidity('assetSourceColumn',assetSourceColumn,updatedGcmIntegrationForm.assetSourceColumn.validation);
                
                }
                else{
                copyForm['assetSourceColumn']['validation']['required'] = true;
                setValidity('assetSourceColumn',assetSourceColumn,updatedGcmIntegrationForm.assetSourceColumn.validation);
                }
                if(isCreateForm){
                    resetAssetSourceColumn(copyForm);
                }
                if(!isViewForm && !isTemplateAccount){
                    if(Array.isArray(assetSourceName) && assetSourceName.length === 0){
                        copyForm.assetSourceColumn.elementConfig.isDisabled = true;
                    }else{
                        copyForm.assetSourceColumn.elementConfig.isDisabled = false;
                        updateAssetSourceColumn(true);
                    }
                }
            }
            else{
                copyForm.assetSourceColumn.elementConfig.isDisabled = true;
            }
            setStateForm(copyForm);
        }
       
    },[assetSourceName])

    useEffect(() => {
        if(!isTemplateAccount){
            let copyForm = {...updatedGcmIntegrationForm}
            if(Array.isArray(assetSourceColumn) && assetSourceColumn.length === 0){
                copyForm['assetSourceColumn']['validation']['required'] = true;
                setValidity('assetSourceColumn',assetSourceColumn,updatedGcmIntegrationForm.assetSourceColumn.validation);
            }
            else{
                copyForm['assetSourceColumn']['validation']['required'] = false;
                setValidity('assetSourceColumn',assetSourceColumn,updatedGcmIntegrationForm.assetSourceColumn.validation);    
            }
            setStateForm(copyForm);
        }
    },[assetSourceColumn])
    /**
     * function to handle change in tracker generation model.
    */
    function handleRadioChange(event, inputIdentifier) {
        const value = event.target.value;
        //update form
        var updatedForm = updateFormElement(inputIdentifier, value, updatedGcmIntegrationForm);
        setStateForm(updatedForm);
        validateSelection(inputIdentifier, updatedForm);       
    }

    /**
     * enableDependentFields: Enable fields waiting for campaign name selection
     * @param {Object} copyForm 
     */
    function enableDependentFields(copyForm) {
        if(!isTemplateAccount) 
			copyForm["dcmCampaignId"].elementConfig.isDisabled = false;
    }

    function enableAdvertiserId() {
        let copyForm = {...updatedGcmIntegrationForm};
        if(!isTemplateAccount) 
			copyForm["dcmAdvertiserId"].elementConfig.isDisabled = false;
        if(isEditForm) {
            copyForm["dcmCampaignId"].elementConfig.isDisabled = false;
            copyForm["verify"].elementConfig.disabled = true;
        }
    }

    /**
     * resetIfNotAllSite: Reset campaign selection when advertiserList changed
     * @param {Object} copyForm 
     */
    function resetCampaign(copyForm) {
        if(copyForm["dcmCampaignId"].value && copyForm["dcmCampaignId"].value.id !== -1) {
            copyForm["dcmCampaignId"].value = null;
        }
    }

    function resetAssetSourceColumn(copyForm) {
        if(copyForm["assetSourceColumn"].value && copyForm["assetSourceColumn"].value.id !== -1) {
            copyForm["assetSourceColumn"].value = null;
        }
    }


    //reset advertiser to false if any change in advertiserList again
    useEffect(() => {
        updateAdvertiser(false);
    },[dcmAdvertiserList]);

    useEffect(() => {
        updateCampaign(false);
    },[dcmCampaignList]);

    useEffect(() => {
        updateAssetSource(false);
    },[assetSourceList]);

    useEffect(() => {
        updateAssetSourceColumn(false);
    },[assetSourceColumnsList]);

    useEffect(() => {
        if(accId && !isTemplateAccount)
        updateAssetSource(true);
    },[])

    useEffect(() => {
		setProfileIdVerified(false);
        if(isEditForm && !isTemplateAccount) {
            updateAdvertiser(true);
            updateCampaign(true);
            updateAssetSource(true);
            if(!!assetSourceName)
                updateAssetSourceColumn(true);
            enableAdvertiserId();
        }
    },[profileIdVal])
    
    const fetchAdvertiserData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: GET_ADVERTISER_DETAILS,
        actionCreator: searchMenuAction,
        endPoint: `/jivox/serverApis/dcm/listAdvertiser?profileId=${profileIdVal}&offset=0&limit=20&accountId=${accId}`,
        isUpdateRequired: isAdvertiserUpdateRequired,
        requestInfo: {
            isDCM: true,
            getData: 'advertiserList'
        }   
    }
    useSubscription(fetchAdvertiserData);

    const fetchCampaignData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: GET_CAMPAIGN_DETAILS,
        actionCreator: searchMenuAction,
        endPoint: `/jivox/serverApis/dcm/listCampaigns?profileId=${profileIdVal}&offset=0&limit=20&advertiserId=${advertiserId}&accountId=${accId}`,
        isUpdateRequired: isCampaignUpdateRequired,
        requestInfo: {
            isDCM: true,
            getData: 'campaignList'
        }   
    }
    useSubscription(fetchCampaignData);

    const fetchAssetSourceData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: GET_ASSET_SOURCE_DATA,
        actionCreator: searchMenuAction,
        endPoint: `/jivox/dcm/assetSource/list?accountId=${accId}`,
        isUpdateRequired: isAssetSourceUpdateRequired,
        requestInfo: {
            isDCM: true,
            getData: 'assetSourceList'
        }   
    }
    useSubscription(fetchAssetSourceData);

    const fetchAssetSourceColumnData = {
        storePath: SAVE_DCM_CONFIGURATION,
        actionType: GET_ASSET_SOURCE_COLUMN_DATA,
        actionCreator: searchMenuAction,
        endPoint: `/jivox/dcm/assetSource/listColumns?accountId=${accId}&dataServiceId=${assetSourceId}`,
        isUpdateRequired: isAssetSourceColumnUpdateRequired,
        requestInfo: {
            isDCM: true,
            getData: 'assetSourceColumnsList'
        }   
    }
    useSubscription(fetchAssetSourceColumnData);

    //Receive message from new tab (authStatus.php file) once authentication is successful.
    const receiveMessageFromIndex = React.useCallback(
        event => {
            var msg = event.data;
            let copyForm = {...updatedGcmIntegrationForm};
            if(typeof(msg) === 'string'){
                var response = msg.split('|');
                if(response[0] === 'windowdata') {
                    var authSuccess = JSON.parse(response[1]);
                    if(authSuccess.status == 200 && authSuccess.message == 'Authentication Successful') {
						if(!isTemplateAccount){
							copyForm['trackerGenerationModel'].disabled = false;
							updateAdvertiser(true);
                        	enableAdvertiserId();
						}
						setProfileIdVerified(true);
                        copyForm["profileId"].validation.required = false;
                        copyForm["profileId"].valid = true;
                        let notIsValid = setValidity('profileId', profileIdVal, updatedGcmIntegrationForm['profileId'].validation);
                    } else {
						setProfileIdVerified(false);
                        copyForm["profileId"].validation.required = true;
                        copyForm["profileId"].valid = false;
                        let notIsValid = setValidity('profileId', profileIdVal, updatedGcmIntegrationForm['profileId'].validation);
                    }
                }
            }
        },[updatedGcmIntegrationForm.dcmAdvertiserId]);

    useEffect(() => {
        // Monitor the message event
        window.addEventListener("message", receiveMessageFromIndex, false);  
        return () =>
            window.removeEventListener("message", receiveMessageFromIndex);  
    }, [receiveMessageFromIndex]);

    function verifyButtonClick(event) {
        event.preventDefault();
        let copyForm = {...updatedGcmIntegrationForm};
        //check if it is valid profile id (no whitespaces, no decimals).
        const isValidProfileId = setValidity('profileId',profileIdVal,copyForm['profileId'].validation);
        if(isValidProfileId && (isCreateForm || isTemplateAccount)) {
            let auth_root = ApiConfigClass.getData(DCM_SERVER_URL);
            window.open(`${auth_root}/jivox/dcm/auth/gateway?profileId=${profileIdVal}&accountId=${accId}`, '_blank');
            copyForm['verify'].elementConfig.disabled = true;
            setStateForm(copyForm); 
        }        
    }

    /**
     * Check if the entered set of values already exists in a different configuration
     */
    function validateSelection(inputIdentifier, updatedForm) {
        const validation = {
            required: true,
            format: "uniqueComboGCM"
        }
        if(inputIdentifier === 'dcmAdvertiserId' || inputIdentifier === 'profileId') {
            // When profileId and advertiserId values change, dcmCampaignId field will be reset.
            // So, we show error message only for the dcmCampaignId field
            setValidity('dcmCampaignId',false,validation) // Clear error message because it's value will be reset
            return;
        }
        if(inputIdentifier === 'configName') {
            setValidity(inputIdentifier, updatedForm['configName'].value, updatedForm['configName'].validation)
            return;
        }
        const profileId = updatedForm['profileId'].value;
        const advertiserId = updatedForm['dcmAdvertiserId'].value ? updatedForm['dcmAdvertiserId'].value.id : null;
        const campaignId = updatedForm['dcmCampaignId'].value ? updatedForm['dcmCampaignId'].value.id : null;
        const duplicateConfiguration = trackerConfiglist.find(config => {
            if(isEditForm && config.id === dcmTrackerConfig.id) return false;
            return config['dcmAdvertiser'].id === advertiserId &&
            config['dcmCampaign'].id === campaignId 
            
        })
        const isDuplicate = !!duplicateConfiguration;
        const errorMessage = isDuplicate ? 
            `The Advertiser, GCM Campaign combination already exists in 
            '${duplicateConfiguration.configName}'. Please use the mentioned configuration.`
            : '';
        // set/clear error message based on the existence of duplicate configuration
        setValidity('dcmCampaignId', isDuplicate, { ...validation, errorMessage }); 
        setSubmitDisabled(isDuplicate); // Submit button will be disabled if a duplicate configuration exists
    }
    
    function handleSelectChange(value, action){
        var inputIdentifier = action.name;
        var value = value === null ? [] : value;
        
        // update form state
        var updatedForm = updateFormElement(inputIdentifier, value, updatedGcmIntegrationForm);
        setStateForm(updatedForm);
        validateSelection(inputIdentifier, updatedForm);
        let copyForm = {...updatedGcmIntegrationForm};
        if(isEditForm) {
            if(inputIdentifier === 'dcmAdvertiserId') {
                updateCampaign(false)
                resetCampaign(copyForm);
            }
            if(inputIdentifier === 'dcmCampaignId' && !copyForm['dcmAdvertiserId'].touched) {
                copyForm['dcmAdvertiserId'].touched = true;
            }
            if(inputIdentifier === 'assetSource'){
                updateAssetSourceColumn(false);
                resetAssetSourceColumn(copyForm);
            }
        }
        props.checkIfFormEdited(true);
    }

    function handleSelectInputChange(inputValue, inputIdentifier){
        if(inputValue) {
            var updatedForm = selectInputChange(inputValue, inputIdentifier, updatedGcmIntegrationForm);
            setStateForm(updatedForm);
        }
    }

    function handleInputChange(event, inputIdentifier, selection){
        var updatedForm = inputChangedHandler(event, inputIdentifier, selection, updatedGcmIntegrationForm);
        validateSelection(inputIdentifier,updatedForm);
		if(inputIdentifier === 'profileId' && isTemplateAccount){
			updatedForm['verify'].elementConfig.disabled = false;
		}
        else if(inputIdentifier === 'profileId' && isCreateForm) {
            updatedForm['verify'].elementConfig.disabled = false;
            updatedForm['dcmAdvertiserId'].elementConfig.isDisabled = true;
            updatedForm['dcmAdvertiserId'].value = '';
            updatedForm['dcmCampaignId'].elementConfig.isDisabled = true;
            updatedForm['dcmCampaignId'].value = '';
            updatedForm['trackerGenerationModel'].disabled = true;
            updatedForm['trackerGenerationModel'].value = modelTypeEnum.MODEL1;
        }
       
        setStateForm(updatedForm);  

        props.checkIfFormEdited(true);

    }
   
    function showToolTip(e){
        setTooltipId(e.currentTarget.getAttribute('data-for'));
        setTooltipContent(e.target.getAttribute('tooltipcontent'));
        toggleTooltip(true);
    }

    function hideToolTip(e){
        setTooltipId(-1);
        setTooltipContent("");
        toggleTooltip(false);
    }
    var toolTip = null;

    if(showTooltip)
        toolTip = <ReactTooltip html={true}  source={toolTipContent} id={tooltipId} position={'bottom'} cssclass={'helpText'} type={'dark'}/>;
    else
        toolTip = "";
   
    function submitForm(event){
        event.preventDefault();
        let formData = {
            type: isCreateForm ? 'createForm' : 'editForm'
        };

        var processFormData =  formSubmit(event, updatedGcmIntegrationForm, formData, payloadType.ENTIRE_FORM);
        if(processFormData.formValid === false) return;
        var postData = processFormData.postData;
        //To Remove unwanted data from postData
        postData = omit(postData,'verify');
		postData.isDCM = true;
        postData.isTemplateAccount = isTemplateAccount;
        if (!isTemplateAccount){
        	postData.dcmCampaignName = updatedGcmIntegrationForm['dcmCampaignId']['value']['name'];
        }
        if(isCreateForm) {
            postData.profileId = parseInt(postData.profileId);
        }
        if(assetSourceName && assetSourceColumn){
            postData.dataServiceId = assetSourceId;
            postData.assetSourceColumnName = assetSourceColumn.name;
        } 
        if(processFormData.formTouched) {
            if(isEditForm) {
                onEditConfig(postData);
            } else {
                onSaveConfig(postData);
            }
        } else {
            if(isEditForm) {
                let msg = 'No changes made to the Media Plan to update.';
                let toastrOptions = {
                    icon: 'error',
                    status: 'error'
                };
                let title = 'Edit Configuration';

                toastr.light(title, msg, toastrOptions) 
            }
        }
    }
    const
        formElementsArray = [],
        formData = {
            formName: 'gcmForm',
            formElementsArray: formElementsArray,
            btnObj: {
                btnGroup: true,
                id: "btnPartnerIntegrate",
                buttonTitle: isEditForm ? "Update" : "Save",
                divclass: "text-right btn-act",
                faIcon: "far fa-save margin-right-5",
                faIconSec: "fal fa-times margin-right-5",
                idSec: "btnCancelPartnerIntegrate",
                buttonTitleSec: "Cancel",
                isLoading: !!isLoading,
                disabled: (!!isLoading || isSubmitDisabled) || (isViewForm || (isTemplateAccount && !profileIdVerified)),
                handleCancel: handleButtonClick
            },
            handleSelectChange: handleSelectChange,
            handleFormBtnClick: verifyButtonClick,
            handleSelectInputChange: handleSelectInputChange,
            inputChangedHandler: handleInputChange,
            submitHandler: submitForm,
            showToolTip: showToolTip,
            hideToolTip: hideToolTip,
            handleRadioChange: handleRadioChange,
            toolTip: toolTip,
            chunkSize: chunkSize,
            dynamicFormRow: 'dynamic-form-row',
            selectDataSource: {
                advertiserList: dcmAdvertiserList,
                campaignList: dcmCampaignList,
                assetSourceList: assetSourceList,
                assetSourceColumnsList: assetSourceColumnsList
            },
            accountId: accId,
            advertiserId: advertiserId,
            profileId: profileIdVal,
            assetSourceId: assetSourceId
        };

    for (let key in updatedGcmIntegrationForm) {
        formElementsArray.push({
            id: key,
            config: updatedGcmIntegrationForm[key]
        });
    }

    const panelBody = (
		<div className={ styles.configurationForm }ref={forwardedRef}>
			<CreateForm formData={formData} />
		</div>
	);

    return (
        <div>
            <ReduxToaster title='Configure Tracker' success={success} storePath={SAVE_DCM_CONFIGURATION}
            successMsg={ !isEditForm ? SAVE_DCM_CONFIGURATION_SUCCESS : UPDATE_DCM_CONFIGURATION_SUCCESS} error={trackerSaveErr} />   
            <div className={ styles.configurationFormContainer }>
                <CustomizedExpansionPanels
                    hookToggle={ setExpanded }
                    title={ `Add new ${partnersInfo[Partners.GCM].label} Configuration` }
                    panelBody={ panelBody }
                    expanded={ isExpanded }
                />
            </div>
        </div>
    );
};
