import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { toastr } from 'react-redux-toastr';

import {isEmpty, generateMenuEndPoint} from '../../helper/commonFunc';

import {searchMenuAction} from '../../app/actions/appAction';

export function AsyncSelectComp(props) {
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState(false);
    const { index, formelement, multiselect, isdisabled, parent, searchmenudetails, datasource } = props;
    const { handleSelectInputChange, handleSelectChange, getOptionValue, getOptionLabel } = props.parent;
    let defaultOptions = formelement.elementConfig.defaultOptions ? true : datasource;
    let defaultMenuIsOpen = !!formelement.elementConfig.defaultmenuisopen ? true : false;
    const menuPlacement = formelement.elementConfig.menuPlacement;
    let isClearable = (formelement.elementConfig.isClearable !== undefined) ? formelement.elementConfig.isClearable : true;
    const disableWhileLoading = !!formelement.elementConfig.disableWhileLoading;
   
    const fetchMenu = (searchItem) => {
        if(!isEmpty(searchmenudetails)) {
            setIsLoading(true);
            let menuDetails = generateMenuEndPoint(searchmenudetails, searchItem);

            return new Promise((resolve, reject) => {
                dispatch(searchMenuAction(menuDetails, (response) => {
                    if(response.error) {
                        const toastrOptions = {
                            icon: 'error',
                            status: 'error'
                        };
                        toastr.light('Error', response.error, toastrOptions);
                        reject("Error");

                    } else {
                        formatMenuOptions(resolve, response, menuDetails.getData, menuDetails.groupHeader)
                    }
                    setIsLoading(false);
                }));
            })
        }
    }

    function formatMenuOptions(resolve, response, getData, groupHeader) {
        let options = response[getData];
        let formatBrandData;

        switch(groupHeader) {
            case 'brandData':
                formatBrandData = [
                    {
                        id: -1,
                        name: "Don't Use Brand Data"
                    },
                    {
                        label: "Select Brand data to use",
                        options
                    }
                ];
                resolve(formatBrandData);
                break;
            case 'campaign':
                formatBrandData = [
                    {
                        id: -1,
                        campaignName: "All Campaigns"
                    },
                    ...options
                ];
                resolve(formatBrandData);
                break;
            case 'site':
                formatBrandData = [
                    {
                        id: -1,
                        siteName: "All Sites"
                    },
                    ...options
                ];
                resolve(formatBrandData);
                break;
            case 'assetSourceList':
                if(options.length) {
                    formatBrandData = options.map(item => ({
                        id: item.dataServiceId,
                        name: item.dataServiceName
                    }));
                    resolve(formatBrandData)
                }
                else resolve(options)
                break;
            case 'assetSourceColumnsList':
                if(options.length) {
                    formatBrandData = options.map((item, index) => ({
                        id: index,
                        name: item.trim()
                    }));
                    resolve(formatBrandData)
                }
                else resolve(options)
                break;
            default:
                resolve(options);
                break;    
        }
    }
    const fetchMenuFunc = props.fetchMenu ? props.fetchMenu : fetchMenu;
    // const rootHeight =  document.getElementById('root').offsetHeight ;
    // const selectElement = document.getElementById('async_select_container_id');
    // const selectOffsetBottom=  selectElement ? selectElement.offsetHeight  + selectElement.offsetTop : null;
    return (
        <div className={formelement.toolTipContent?"asyncSelect-tooltip":""}>
        <AsyncSelect
            defaultMenuIsOpen={defaultMenuIsOpen}
            name={index}
            getOptionValue={getOptionValue}
            getOptionLabel={(option) => getOptionLabel(option, formelement, parent)}
            value={formelement.value}
            placeholder={formelement.elementConfig.label}
            isClearable={isClearable}
            isMulti={multiselect}
            isDisabled={isdisabled || (disableWhileLoading ? isLoading : false)}
            closeMenuOnSelect={multiselect ? false : true}
            cacheOptions={true}
            menuPlacement={ menuPlacement ? menuPlacement : 'auto' }
            defaultOptions={defaultOptions}
            loadOptions={isdisabled ? null : (inputValue) => fetchMenuFunc(inputValue)}
            onChange={handleSelectChange}
            onInputChange={(value) => handleSelectInputChange(value, index)}
        />
        {!!formelement.toolTipContent ? <span className="info far fa-question-circle" onMouseEnter={(e)=>parent.showToolTip(e)} onMouseLeave={(e)=>parent.hideToolTip(e)} tooltipcontent={formelement.toolTipContent} data-tip data-for={index} ></span> : null}
        </div>
    );
}

